import './index.scss';
import AppLoader from "./AppLoader";

const loader = new AppLoader({
    'manage': () => import('./page/manage/IndexConfig'),
    "starcloud": () => import('./page/starcloud/IndexConfig'),
    'enterprise': () => import('./page/enterprise/IndexConfig'),
});

loader.load();
