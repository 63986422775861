import {render} from '@alipay/isportal-app-web-base';
import '@alipay/isportal-app-web-base/lib/index.css';

const Error = ({msg}) => {
    return <div className='is-error-page'>{msg}</div>
}

class AppLoader {
    constructor(modules) {
        this.modules = modules;
    }

    load() {
        let m = window.model;
        if (!m || m === "${model}") {
            const matcher = window.location.pathname.match(/^\/(.+)\.htm$/);
            if (matcher) {
                m = matcher[1];
            } else {
                m = 'starcloud';
            }
        }
        const module = this.modules[m];
        if (!module) {
            throw new window.Error("model not found: " + m);
        } else {
            module().then(config => {
                const {init, login, hosts, headers, App} = config.default;
                render(App, Error, {
                    hosts,
                    headers,
                    init,
                    login
                })
            });
        }
    }
}

export default AppLoader;
